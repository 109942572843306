<template>
  <div>
    <!-- ヘッダー -->
    <v-app-bar app color="orange darken-1" dark dense height="40px">
      <v-row>
        <v-col cols="10" style="margin-top: 10px"> </v-col>
        <v-col cols="2">
          <div align="right">
            <v-btn text fab small @click="exit()">
              <v-icon large>mdi-home</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
    <center>
      <!-- 結果の要約 -->
      <v-card color="ffffff" outlined width="95%" style="margin: 20px">
        <v-system-bar color="grey" />
        <v-card-title class="orange--text text--dark-1"
          ><b>結果 {{ startTime }}</b></v-card-title
        >
        <v-card-title v-if="assIsPass != null" style="margin-top: -30px">
          <div v-if="assIsPass">
            <font color="red" size="5"><b>合格🌸</b></font>
          </div>
          <div v-if="!assIsPass">
            <font color="blue" size="5"><b>不合格</b></font>
          </div>
        </v-card-title>
        <v-card-text style="font-size: 25px">
          <v-row>
            <v-col cols="12" sm="12" md="4">解答時間 {{ timeAll }}</v-col>
            <v-col cols="12" sm="12" md="4">正解数 {{ amountAll }}</v-col>
            <v-col cols="12" sm="12" md="4">正答率 {{ correctPerAll }}%</v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- セクション別正答率 -->
      <v-card
        color="ffffff"
        outlined
        width="95%"
        style="margin-top: 15px; padding-bottom: 15px"
      >
        <v-system-bar color="grey" />
        <v-card-title
          style="margin-bottom: -20px"
          class="orange--text text--dark-1"
        >
          <b>セクション別の結果</b>
        </v-card-title>
        <div
          v-for="tangen in resultSectionObj"
          :key="tangen.sectionId"
          style="text-align: left"
        >
          <v-card-subtitle style="margin-bottom: 10px">
            <span class="bar"
              >{{ tangen['name'] }} ( {{ tangen[`correctPer`] }}% )</span
            >
          </v-card-subtitle>
          <v-card-text>
            <v-row style="margin-bottom: -25px">
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="4"
                v-for="section in tangen[`sections`]"
                :key="section.sectionId"
              >
                <v-row style="margin: -20px 0px 0px">
                  <v-progress-linear
                    :value="section[`correctPer`]"
                    color="red lighten-4"
                    buffer-value="0"
                    stream
                    height="35"
                    width="100%"
                  >
                    <div style="width: 100%; line-height: 1">
                      {{ section[`name`] }} ( {{ section[`correctPer`] }}% )
                    </div>
                  </v-progress-linear>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
      <!-- 問題別正答率 -->
      <v-card color="ffffff" outlined width="95%" style="margin-top: 15px">
        <v-system-bar color="grey" />
        <v-card-title
          style="margin-bottom: -20px"
          class="orange--text text--dark-1"
          ><b style="margin-right: 20px">問題別の結果</b>
          <span v-if="!isLogined" class="account-prompt">
            <a href="/">アカウント作成（無料）</a>すると結果を履歴として残せます
          </span>
        </v-card-title>
        <v-card-text>
          <div class="scroll-table">
            <v-data-table
              v-model="selectedExam"
              :headers="headers"
              :items="itemsExam"
              :items-per-page="400"
              fixed-header
              height="500px"
              mobile-breakpoint="0"
              hide-default-footer
            >
              <!-- No slotしないとマージンの制御が難しい-->
              <template v-slot:[`item.num`]="itemsExam">
                <div>{{ itemsExam.item.num }}</div>
              </template>
              <!-- 正誤 -->
              <template v-slot:[`item.isCorrect`]="itemsExam">
                <div v-if="itemsExam.item.isCorrect == true">
                  <v-icon color="red lighten-3" medium
                    >mdi-circle-outline</v-icon
                  >
                </div>
                <div v-else>
                  <v-icon color="blue" medium>mdi-close-thick</v-icon>
                </div>
              </template>
              <!-- 問題ID shoqQestionのリンクがあり、コマンド問題では使用しないので非表示-->
              <template
                v-slot:[`item.id`]="itemsExam"
                v-if="isCommand == false"
              >
                <div v-if="itemsExam.item.isCorrect == true">
                  <v-btn
                    text
                    @click="openQuestion(itemsExam.item.id)"
                    color=""
                    style="text-transform: none; padding: 0px 5px"
                    >{{ itemsExam.item.id }}</v-btn
                  >
                </div>
                <div v-else>
                  <v-btn
                    text
                    @click="openQuestion(itemsExam.item.id)"
                    color="blue"
                    style="text-transform: none; padding: 0px 5px"
                    >{{ itemsExam.item.id }}</v-btn
                  >
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </center>
  </div>
</template>

<style>
.scroll-table {
  overflow: hidden;
  white-space: nowrap;
}
.v-data-table td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
}
.bar {
  border-left: 3px solid black;
  padding-left: 10px;
}
.account-prompt {
  font-size: 15px;
  color: #000000de;
  text-align: left;
}
.account-prompt a:hover {
  color: #0099ff;
}
</style>

<script>
import { mapState } from 'vuex';
export default {
  name: 'result',
  components: {},
  data() {
    return {
      //======================================================================================
      startTime: '',
      timeAll: '',
      amountAll: '',
      correctPerAll: '', //結果の要約
      resultSectionObj: {}, //セクション別の結果
      itemsExam: [], //データテーブル
      selectedExam: [],
      headers: [
        { text: 'No', value: 'num', width: '1px' },
        { text: '正誤', value: 'isCorrect', width: '1px' },
        { text: '問題ID', value: 'id', width: '150px' },
        { text: '単元', value: 'tangenName', width: '200px' },
        { text: 'セクション', value: 'sectionName', width: '200px' },
        { text: '内容', value: 'question', width: '800px' },
      ],
      sec: 0, //合計取り組み時間（秒）課題の記録で使用する
      isStudyLog: false, //学習履歴から来ているか否か
      isCommand: false, //コマンド問題か否かを制御、showQuestionする時に必要
      assIsPass: null, //空白でなければ（ture or false）表示する。
    };
  },
  computed: {
    ...mapState(['saveData', 'user']),
    sectionObj() {
      const kamokuName = this.saveData.selKamokuName;
      const obj = this.$store.state[kamokuName].sectionObj;
      return obj;
    },
    option() {
      const kamokuName = this.saveData.selKamokuName;
      const option = this.$store.state[kamokuName].option;
      return option;
    },
    isLogined() {
      return this.gf_isLogin();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.webApp.allowLeavePage) {
      next();
    } else {
      //exitする場合は、allowLeavePage = true にしてからrouter操作をするので、2週目でnext()できる
      alert('⚠このページから前のページへは戻れません。'); //ブラウザバックをしている状況
      next(false);
    }
  },
  created() {
    window.addEventListener('beforeunload', this.handler);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.handler);
  },
  async mounted() {
    this.$store.commit('loading', true);

    //状態維持
    await this.gf_keepActive();
    await this.gf_keepLogin();

    if (this.saveData.selKamokuName == '') {
      const res = await this.checkSessionStorage();
      if (res) {
        this.isStudyLog = true; //Studylogから来ていると、FSのデータ用の処理が必要。Dateオブジェクトとか
      } else {
        this.$store.commit('leavePage', true); //直接resultページを開いているのでトップに送る
        this.$router.push('/');
        return;
      }
    }

    //科目データの整備
    const kamokuName = this.saveData.selKamokuName;
    // TODO:新データへの移行が終わり次第以下if文ロジックを削除してawait this.gf_set_subjectData(kamokuName);だけにする。
    this.gf_isNewType(kamokuName)
      ? await this.gf_set_subjectData(kamokuName)
      : await this.gf_set_kamokuData(kamokuName);

    this.$store.commit('mainHeader', false);
    this.$store.commit('leavePage', false);
    this.setContents();
    this.$store.commit('loading', false);
  },
  methods: {
    //saveDataが無い場合でも、学習記録から飛んでいるパターンがある
    async checkSessionStorage() {
      const data = JSON.parse(sessionStorage.getItem('data'));
      if (data == null) {
        return false; //直接Resultを開いている状況
      }

      this.$store.commit('update_store', {
        path: 'state',
        key: 'saveData',
        data: data.saveData,
      });

      sessionStorage.clear();
      return true;
    },
    //コンテンツを配置する
    setContents() {
      this.integrateAnswerDataAndQuestions();
      this.setScoreSummary();

      const kamokuName = this.saveData.selKamokuName;
      // TODO:新データ以降完了後 this.createResultSectionObj();だけにする（ロジックは不要）
      if (
        kamokuName === 'ccna' ||
        kamokuName === 'ciscoCommand' ||
        kamokuName === 'linuc101Command' ||
        kamokuName === 'linuc102Command'
      ) {
        this.createResultSectionObj4OldData();
      } else {
        this.createResultSectionObj();
      }
      this.createResultPerQuestion();

      const kamoku_type = this.gf_get_kamokuType(kamokuName);

      if (kamoku_type == 'command') {
        this.isCommand = true;
      }

      //課題の場合、合否を判定する。
      const isAssignment = this.$store.state.perm.isAssignment;
      if (isAssignment) {
        const isPass = this.judge_assignment(
          this.saveData,
          this.correctPerAll,
          this.sec,
        );
        this.assIsPass = isPass;
      }
    },
    //answerDataのidから、必要な情報をquestionsから取得する
    integrateAnswerDataAndQuestions() {
      const kamokuName = this.saveData.selKamokuName; //saveDataにはidしかないので、問題データからセクションIdを取得する
      const Q = this.$store.state[kamokuName].questions.questions;
      const arrayObj = this.utils_create_arrayObjFromObj(Q);

      const answerData = this.saveData.answerData;
      Object.keys(answerData).forEach((ind) => {
        const ele = answerData[ind];
        const id = ele.id;
        const i = arrayObj.findIndex((data) => data.id == id);
        //ユーザーのansweDataにはidが残っているが、DBの問題削除などで問題側にidが無い場合
        if (i == -1) {
          delete answerData[ind];
          return;
        }
        const obj_question = arrayObj[i];
        ele.question = obj_question;
      });
    },
    //結果の要約
    //==================================================
    setScoreSummary() {
      //開始日時の取得
      let startTime = Number(this.saveData.startTime);
      this.startTime = this.utils_formatDate(new Date(startTime), 'Y/M/D h:m');

      //正解数と問題数
      const ansData = this.saveData.answerData;
      const arrayObj_ansData = this.utils_create_arrayObjFromObj(ansData);
      const amount = arrayObj_ansData.length;
      const correctAmount = arrayObj_ansData.filter(
        (data) => data['isCorrect'],
      ).length;
      const correctPer = Math.round((correctAmount / amount) * 100);
      this.amountAll = correctAmount + '/' + amount;

      //正答率、課題の合否判定でも利用しているので、数字ね。
      this.correctPerAll = correctPer;

      //解答時間（StudyLogから来ていると、FSデータ用の処理が必要）
      if (this.isStudyLog) {
        this.timeAll = this.gf_getActivityTimeFS(this.saveData);
      } else {
        const startTime = Number(this.saveData.startTime);
        const endTime = Number(this.saveData.endTime);
        const sec = Math.round((endTime - startTime) / 1000);
        this.sec = sec;
        this.timeAll = this.utils_formatSecond(sec);
      }
    },
    //セクション別正答率
    //==================================================
    createResultSectionObj() {
      const item = {};
      const selSectionId = this.utils_create_arrayObjFromObj(
        this.saveData.selSectionId,
      );
      const answerData = this.utils_create_arrayObjFromObj(
        this.saveData.answerData,
      );
      const kamokuName = this.saveData.selKamokuName;
      const sectionLists = Object.entries(
        this.$store.state[kamokuName].questions.sectionsList,
      );

      //単元構造の作成
      selSectionId.forEach((ele) => {
        const tangenId = ele['tangenId'];
        if (item[tangenId] == undefined) {
          const tangenObj = Object.assign({}, this.sectionObj[tangenId]); //オブジェクトの値渡し
          const targetSectionIds = sectionLists.reduce((prev, curr) => {
            return curr[1].tangenId === tangenId ? [...prev, curr[0]] : prev;
          }, []);
          const targetAnswerData = answerData.filter((data) => {
            return targetSectionIds.includes(
              data.sectionId ?? data.question.sectionId,
            );
          });
          const correctPerTangen = this.scoringTangen(targetAnswerData);
          tangenObj['correctPer'] = correctPerTangen;
          item[tangenId] = tangenObj;
          item[tangenId]['sections'] = {}; //余分なセクションが入っているので空にする
        }
      });

      //セクション情報の記入
      selSectionId.forEach((ele) => {
        const tangenId = ele['tangenId'];
        const sectionId = ele['sectionId'];
        const sectionObj = Object.assign(
          {},
          this.sectionObj[tangenId]['sections'][sectionId],
        ); //オブジェクトの値渡し
        const correctPerSection = this.scoringSection(sectionId, answerData); //セクションの採点
        if (correctPerSection !== false) {
          //0とfalseを区別する必要があるよ！
          sectionObj['correctPer'] = correctPerSection;
          item[tangenId]['sections'][sectionId] = sectionObj;
        }
      });
      this.resultSectionObj = item;
    },
    scoringTangen(answerData) {
      const totalCorrectAmount = answerData.filter(
        (data) => data['isCorrect'],
      ).length;
      const correctPer = Math.round(
        (totalCorrectAmount / answerData.length) * 100,
      );
      return correctPer;
    },
    scoringSection(sectionId, answerData) {
      const sectionData = answerData.filter(
        (data) => data['sectionId'] ?? data.question['sectionId'] == sectionId,
      );
      const amount = sectionData.length;
      if (amount == 0) {
        //途中離脱でセクションの解答情報が無い場合がある
        return false;
      } else {
        const correctAmount = sectionData.filter(
          (data) => data['isCorrect'] == true,
        ).length;
        const correctPer = Math.round((correctAmount / amount) * 100);
        return correctPer;
      }
    },
    // TODO:新データ以降完了後 createResultSectionObj4OldDataは削除
    createResultSectionObj4OldData() {
      const item = {};
      const selSectionId = this.utils_create_arrayObjFromObj(
        this.saveData.selSectionId,
      );
      const answerData = this.utils_create_arrayObjFromObj(
        this.saveData.answerData,
      );
      //単元構造の作成
      selSectionId.forEach((ele) => {
        const tangenId = ele['tangenId'];
        if (item[tangenId] == undefined) {
          const tangenObj = Object.assign({}, this.sectionObj[tangenId]); //オブジェクトの値渡し
          const correctPerTangen = this.scoringTangen4OldData(
            tangenId,
            answerData,
          );
          tangenObj['correctPer'] = correctPerTangen;
          item[tangenId] = tangenObj;
          item[tangenId]['sections'] = {}; //余分なセクションが入っているので空にする
        }
      });
      //セクション情報の記入
      selSectionId.forEach((ele) => {
        const tangenId = ele['tangenId'];
        const sectionId = ele['sectionId'];
        const sectionObj = Object.assign(
          {},
          this.sectionObj[tangenId]['sections'][sectionId],
        ); //オブジェクトの値渡し
        const correctPerSection = this.scoringSection(sectionId, answerData); //セクションの採点
        if (correctPerSection !== false) {
          //0とfalseを区別する必要があるよ！
          sectionObj['correctPer'] = correctPerSection;
          item[tangenId]['sections'][sectionId] = sectionObj;
        }
      });
      this.resultSectionObj = item;
    },
    // TODO:新データ以降完了後 scoringTangen4OldDataは削除
    scoringTangen4OldData(tangenId, answerData) {
      const tangenEnd = Number(tangenId) + 100;
      const tangenData = answerData.filter(
        (data) => data['sectionId'] > tangenId && data['sectionId'] < tangenEnd,
      );
      const amount = tangenData.length;
      const correctAmount = tangenData.filter(
        (data) => data['isCorrect'] == true,
      ).length;
      let correctPer = 0;
      if (amount != 0) {
        correctPer = Math.round((correctAmount / amount) * 100);
      }
      return correctPer;
    },
    //問題別データ表示
    //==================================================
    createResultPerQuestion() {
      const obj_ansData = this.saveData.answerData;
      const itemsExam = [];
      const kamokuName = this.saveData.selKamokuName;

      Object.keys(obj_ansData).forEach((ind) => {
        const ele = obj_ansData[ind];
        const sectionId = ele.question.sectionId;
        const tangenId = this.getSectionInfo(sectionId, 'tangenId');
        const record = {
          num: Number(ind) + 1,
          isCorrect: ele['isCorrect'],
          id: ele.id,
          isMemo: false, //メモの取得未開発
          // TODO:新データへの移行が終わり次第getTangenInfoだけにする。
          tangenName: this.gf_isNewType(kamokuName)
            ? this.getTangenInfo(tangenId, 'name')
            : this.getSectionInfo(tangenId, 'name'),
          sectionName: this.getSectionInfo(sectionId, 'name'),
          question: this.isCommand
            ? ele.question.questionS
            : ele.question.question,
        };
        itemsExam.push(record);
      });
      this.itemsExam = itemsExam;
    },
    getSectionInfo(sectionId, key) {
      const kamokuName = this.saveData.selKamokuName;
      // TODO:新データへの移行が終わり次第sectionsListだけにする。
      const obj = this.gf_isNewType(kamokuName)
        ? this.$store.state[kamokuName].questions.sectionsList
        : this.$store.state[kamokuName].questions.sectionList;
      const sectionList = this.utils_create_arrayObjFromObj(obj);
      // TODO:新データへの移行が終わり次第idだけにする。
      const ind = sectionList.findIndex(
        (data) =>
          data[this.gf_isNewType(kamokuName) ? 'id' : 'sectionId'] == sectionId,
      );
      const tangenId = sectionList[ind][key];
      return tangenId;
    },
    getTangenInfo(tangenId, key) {
      const kamokuName = this.saveData.selKamokuName;
      const obj = this.$store.state[kamokuName].questions.tangensList;
      const tangensList = this.utils_create_arrayObjFromObj(obj);
      const ind = tangensList.findIndex((data) => {
        return data.id == tangenId;
      });

      const targetTangenInfo = tangensList[ind][key];
      return targetTangenInfo;
    },
    //課題
    //==================================================
    judge_assignment(saveData, correctPerAll, sec) {
      const targetPer = Number(saveData.option.selTargetPer);
      const resultPer = Number(correctPerAll);
      if (targetPer <= resultPer) {
        this.update_assignment(resultPer, sec);
        return true;
      }
      return false;
    },
    async update_assignment(resultPer, sec) {
      const uid = this.user.auth.uid;
      const assId = this.$store.state.perm.assignmentId;
      const obj = {
        data: {
          [assId]: {
            achievement: {
              [uid]: {
                date: new Date().getTime(),
                resultPer: resultPer,
                sec: sec,
              },
            },
          },
        },
      };
      const companyId = this.user.claims.companyId;
      await this.utils_merge_firestore('assignment', companyId, obj).then(
        () => {
          this.gf_getAssignment(true); //強制ロード
        },
      );
    },

    //その他機能
    //==================================================
    //問題を新しいタブで開く
    openQuestion(id) {
      var userData = id;
      //studyLogではなく、問題演習から流入している場合
      if (!this.isStudyLog) {
        const ansData = this.saveData.answerData;
        const arrayObj_ansData = this.utils_create_arrayObjFromObj(ansData);
        const ind = arrayObj_ansData.findIndex((data) => data.id == id);

        //問題演習の時はレコードを送る。通常検索はIDを送っている。
        userData = arrayObj_ansData[ind];
      }

      const kamokuName = this.saveData.selKamokuName;
      const q = this.$store.state[kamokuName].questions.questions;
      const arrayObj_q = this.utils_create_arrayObjFromObj(q);
      const ind = arrayObj_q.findIndex((data) => data['id'] == id);
      const ele_q = arrayObj_q[ind];

      const data = {
        isSearch: this.isStudyLog,
        selKamokuName: kamokuName,
        userData: userData,
        question: ele_q,
      };

      sessionStorage.setItem('data', JSON.stringify(data));
      let resolvedRoute = this.$router.resolve({ path: '/showQuestion' }); //コマンド問題の個別表示はない。
      window.open(resolvedRoute.href, '_blank');
    },
    exit() {
      //問題からの離脱、ボタンならtrue、ブラウザバックならundifinedとなる
      const kamokuName = this.saveData.selKamokuName;
      this.$store.commit('leavePage', true);
      this.$router.push('/' + kamokuName);
    },
  },
};
</script>
